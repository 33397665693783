import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private apiUrl = 'https://api.dev.nimbuz.cloud/project-management/v1/projects';

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  getAllProjects(){
    return this.http.get(`${this.apiUrl}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getProjectDetailsById(projectId:string){
    return this.http.get(`${this.apiUrl}/${projectId}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getEnvironmentsByProject(projectId:string){
    return this.http.get(`${this.apiUrl}/${projectId}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getDeploymentsByProject(projectId:string){
    return this.http.get(`${this.apiUrl}/${projectId}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  createProject(req: any) {
    return this.http.post(`${this.apiUrl}/`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  updateProject(projectId: string, req: any) {
    return this.http.put(`${this.apiUrl}/${projectId}`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  deleteProject(projectId:string){
    return this.http.delete(`${this.apiUrl}/${projectId}`)
    .pipe(
      catchError(this.handleError.bind(this))
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      this.toastr.error(error.error?.error)
    } else {
      const errorMessage = error.error?.error || 'Please try again later';
      this.toastr.error(errorMessage, 'Error');
    }
    return throwError('Something bad happened; please try again later.');
  }

}
